import { MobileMockup } from "@/icons";
import Image from "next/image";
import type React from "react";
import FAQ from "../frequentQuestions";
import { Reveal } from "../revealPageItems";

const Section7: React.FC = () => {
  // Dados para o FAQ
  const faqItems = [
    {
      id: 1,
      title: "Gestão da dor",
      description:
        "A cannabis medicinal pode atuar no alívio de dores crônicas e agudas, proporcionando uma alternativa natural e eficaz para pacientes que sofrem com desconforto contínuo.",
    },
    {
      id: 2,
      title: "Ansiedade e estresse",
      description:
        "Estudos indicam que o uso de cannabis medicinal pode ajudar a reduzir níveis de ansiedade e estresse, promovendo uma sensação de relaxamento e equilíbrio emocional.",
    },
    {
      id: 3,
      title: "Melhoria do sono",
      description:
        "Pacientes relatam melhorias significativas na qualidade do sono com o uso de cannabis medicinal, ajudando a combater a insônia e proporcionar noites de descanso mais reparadoras.",
    },
    {
      id: 4,
      title: "Doenças neurológicas",
      description:
        "O tratamento com cannabis medicinal tem sido explorado em diversas condições neurológicas, como epilepsia e esclerose múltipla, oferecendo uma alternativa terapêutica promissora para esses pacientes.",
    },
    {
      id: 5,
      title: "Saúde mental",
      description:
        "Além de aliviar a ansiedade, o tratamento com cannabis medicinal tem demonstrado benefícios no apoio ao tratamento de depressão e transtorno de estresse pós-traumático (TEPT), proporcionando maior qualidade de vida aos pacientes que convivem com esses distúrbios.",
    },
  ];

  return (
    <div
      id="beneficios"
      className="flex w-screen max-w-[1216px] flex-col items-center justify-between overflow-x-hidden px-4 py-16 md:flex-row md:px-0 md:py-40"
    >
      <div className="md:w-[600px]">
        <Reveal>
          <h1 className="pb-2 text-start text-2xl leading-relaxed md:text-4xl md:leading-snug">
            Potenciais benefícios do tratamento com cannabis medicinal
          </h1>
        </Reveal>

        <FAQ faqItems={faqItems} />
      </div>
      <Reveal>
        <div className="relative mt-24 flex flex-col items-center justify-between rounded-2xl bg-brand-darker bg-cover bg-no-repeat md:mt-0 md:w-[480px]">
          <Image
            src="/image/pattern.svg"
            alt="Imagem do aplicativo"
            loading="lazy"
            fill
            className="pointer-events-none object-cover object-center"
          />
          <div className="z-10 flex flex-col items-start gap-4 px-4 py-16">
            <span className="rounded-full bg-[#F7DF37] px-4 py-2 text-sm text-black">
              Em breve
            </span>
            <h1 className="pb-2 text-start text-3xl leading-relaxed text-white md:leading-normal">
              Um aplicativo para você chamar de seu!
            </h1>
          </div>

          <div className="flex w-full max-w-full px-4 md:px-6">
            {/* <MobileMockup className='h-auto w-full' /> */}
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default Section7;
