import { motion } from "framer-motion";
import Image from "next/image";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import FAQ from "../frequentQuestions";
import { Reveal } from "../revealPageItems";

const Section9: React.FC = () => {
  // Estado para controlar o modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal
  // Função para abrir o modal
  const openModal = useCallback(() => {
    setIsModalOpen(true);
    document.body.classList.add("no-scroll"); // Desativa o scroll da página ao abrir o modal
  }, []);

  // Função para fechar o modal
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    document.body.classList.remove("no-scroll"); // Reativa o scroll da página ao fechar o modal
  }, []);

  // Função para lidar com o clique fora do modal
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const modal = document.querySelector(".modal-container");
      if (modal && !modal.contains(event.target as Node)) {
        closeModal();
      }
    },
    [closeModal]
  );
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("keydown", handleEscKey);
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen, closeModal, handleClickOutside]);

  // Dados para o FAQ
  const faqItems = [
    {
      id: 1,
      title: "Crianças",
      description:
        "A Cannabis medicinal pode ser indicada para crianças com epilepsia resistente, autismo e outros distúrbios neurológicos, proporcionando alívio seguro e eficaz dos sintomas sob supervisão médica especializada.",
    },
    {
      id: 2,
      title: "Adolescentes",
      description:
        "Adolescentes podem se beneficiar da Cannabis medicinal no tratamento de condições como ansiedade, depressão, TDAH e distúrbios do sono, oferecendo uma alternativa natural para melhorar o bem-estar e qualidade de vida.",
    },
    {
      id: 3,
      title: "Adultos",
      description:
        "Para adultos, a Cannabis medicinal pode auxiliar no tratamento de ansiedade, estresse, dores crônicas, insônia e doenças neurológicas, proporcionando uma alternativa eficaz e natural ao bem-estar.",
    },
    {
      id: 4,
      title: "Idosos",
      description:
        "Idosos podem se beneficiar da Cannabis medicinal no alívio de dores crônicas, insônia, artrite e doenças neurodegenerativas, melhorando o conforto e a qualidade de vida de forma segura e monitorada.",
    },
  ];

  return (
    <div className="flex w-screen max-w-[1216px] flex-col items-center justify-between px-4 pt-40 md:flex-row-reverse md:px-0 md:py-40">
      <div className="md:w-[600px]">
        <Reveal>
          <h1 className="pb-2 text-start text-2xl leading-relaxed md:text-4xl md:leading-snug">
            Quem pode se beneficiar com o tratamento a base de cannabis?
          </h1>
        </Reveal>

        <FAQ faqItems={faqItems} />
      </div>
      <Reveal>
        <div className="flex w-full items-center justify-center px-4 pt-16 md:w-[400px]">
          {/* Substituindo o iframe pela imagem */}
          <div
            className="relative flex h-full w-full cursor-pointer flex-col items-center justify-center py-32 md:py-0"
            onClick={openModal}
            onKeyUp={() => {}}
          >
            <div className="relative">
              <Image
                src="/image/Depoimento-img.webp" // Substitui pela imagem desejada
                alt="Imagem de Depoimento"
                loading="lazy"
                width={1080}
                height={1920}
                className="rounded-2xl"
              />
              {/* Contêiner Flex para centralizar o ícone de play */}
              <motion.div
                className="absolute inset-0 left-[42%] top-[55%] z-20 flex h-16 w-16 items-center justify-center rounded-full bg-brand-light"
                animate={{ scale: [1, 1.2, 1] }} // Pulse effect
                transition={{ duration: 1.5, repeat: Number.POSITIVE_INFINITY }} // Loop infinito
              >
                <Image
                  src="/image/play-icon.svg"
                  alt="Thumbnail"
                  loading="lazy"
                  width={26}
                  height={30}
                />
              </motion.div>
            </div>
            <span className="pb-2 pt-4 text-center text-xl leading-relaxed">
              "Com o apoio incrível da equipe da Click Cannabis e o tratamento
              adequado, minhas dores e crises diárias ficaram para trás"
            </span>
            <span className="text-center text-gray-500">Eliane Conceição</span>
          </div>
        </div>
      </Reveal>

      {/* Modal com o vídeo */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="modal-container relative h-[60%] rounded-lg bg-transparent md:h-[80%]">
            {/* Botão de fechar */}
            <button
              type="button"
              className="absolute right-2 top-2 h-8 w-8 rounded-full bg-white text-black"
              onClick={closeModal}
            >
              X
            </button>
            {/* Vídeo do depoimento */}
            <iframe
              className="h-full w-full rounded-2xl"
              src="https://player.vimeo.com/video/1010016195?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1"
              allow="fullscreen; picture-in-picture; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="Depoimento"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Section9;
