import { CircleMinus, CirclePlus } from "@/icons";
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Reveal } from "../revealPageItems";

interface FaqItem {
  id: number;
  title: string;
  description: string;
}

interface Faq0Props {
  faqItems: FaqItem[];
}

const Faq0: React.FC<Faq0Props> = ({ faqItems }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  // Cria um array de refs, um para cada item
  const refs = React.useRef<React.RefObject<HTMLParagraphElement>[]>(
    faqItems.map(() => React.createRef<HTMLParagraphElement>())
  );

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="mx-auto w-full max-w-[1216px]">
      {faqItems.map((item, index) => {
        const nodeRef = refs.current[index];

        return (
          <div
            key={index}
            className={`w-full border-b py-8 ${
              expandedIndex === index ? "expanded" : ""
            }`}
          >
            <Reveal>
              <div
                className="flex w-full cursor-pointer flex-row items-center justify-between"
                onClick={() => toggleExpand(index)}
                onKeyUp={() => {}}
              >
                <h2 className="faq-title w-full pb-2 text-2xl leading-relaxed">
                  {item.title}
                </h2>
                <div className="ml-4 flex-shrink-0">
                  {expandedIndex === index ? <CircleMinus /> : <CirclePlus />}
                </div>
              </div>
            </Reveal>
            <Reveal>
              <div className="flex w-full flex-col items-end">
                <CSSTransition
                  in={expandedIndex === index}
                  timeout={300}
                  classNames="faq-transition"
                  unmountOnExit
                  nodeRef={nodeRef} // <--- PASSANDO nodeRef PRO CSSTransition
                >
                  <p
                    className="w-[80%] pt-4 leading-relaxed md:w-[60%]"
                    ref={nodeRef} // <--- PASSANDO A REF PARA O ELEMENTO
                  >
                    {item.description}
                  </p>
                </CSSTransition>
              </div>
            </Reveal>
          </div>
        );
      })}

      <style jsx>{`
        .faq-transition-enter {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }

        .faq-transition-enter-active {
          max-height: 500px;
          opacity: 1;
          transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
        }

        .faq-transition-exit {
          max-height: 500px;
          opacity: 1;
          overflow: hidden;
        }

        .faq-transition-exit-active {
          max-height: 0;
          opacity: 0;
          transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default Faq0;
